/**
 * Overall
 */
.gform_wrapper {
	.gform_validation_container {
		display: none !important;
	}
}
.m-form {
	padding: 2rem 0;

	&--nolabels_wrapper {
		label {
			display: none;
		}
	}

	&\:col {
		clear: none !important;

		&li {
			clear: none;
		}
		clear: none;

		&__2 {
			@include media('>=tablet-sm') {
				width: 50%;
				float: left;
			}
		}

		&__4 {
			@include media('>=tablet-sm') {
				width: 25%;
				float: left;
			}
		}

		&__first {
			clear: left;
		}
	}

	$_form_col_gap: 4rem;

	ul {
		margin: 0 calc(#{$_form_col_gap} * -1) 0 0 !important;
		padding: 0 !important;
	}

	li {
		list-style: none;
		margin-bottom: 2rem;
		padding-right: $_form_col_gap;

		&:not(.m-form\:col) {
			clear: left;
		}
	}

	$placeholder-color: $c-gray-md;

	input,
	select,
	textarea {
		width: 100%;
		background: none;
		cursor: pointer;

		&::-webkit-input-placeholder { /* WebKit browsers */
			color: $placeholder-color;
		}
		&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
			color: $placeholder-color;
		}
		&::-moz-placeholder { /* Mozilla Firefox 19+ */
			color: $placeholder-color;
		}
		&:-ms-input-placeholder { /* Internet Explorer 10+ */
			color: $placeholder-color;
		}
	}

	input:not([type="submit"]) {
		border: 1px solid $c-gray-xl;
		border-width: 0 0 .1rem 0;
		height: 4rem;
	    padding: 0;
	    font-size: 1.6rem;

	    &:focus {
	    	border-color: #000;
	    }
	}
	input[type="submit"] {
		border: 0;
		border-radius: 2.2rem;
		color: #fff;
		font-size: 1.6rem;
		font-weight: 700;
		width: auto;
		padding: 1rem 1.5rem;
		height: auto;
		background: $c-brand-orange;
		transition: all .3s ease-in-out;

		@include media('>=phone-md') {
			padding: 1.5rem 5rem;
			font-size: 1.8rem;
		}

		&:hover {
			background: lighten($c-brand-orange, 5%);
		}

		//@include gradient-orange;
	}

	select:not(.m-select) {
		height: 5rem;
	    padding: 0 2rem;
	    font-size: 1.6rem;
	    margin-top: .5rem;
	    background-image: url(../images/icn_select_arrow.svg);
	    background-repeat: no-repeat;
	    background-position: calc(100% - 2rem) calc(50% - .1rem);
	    background-size: 1rem auto;
	    cursor: pointer;
	    border-radius: 0;
	    border: 0;
	    border-bottom: 1px solid $c-gray-xl;
	    font-family: $font-h;

	    &:focus {
			border-color: $c-gray-d;
		}
	}

	textarea {
		border-radius: 0;
		margin-top: .5rem;
		font-size: 1.6rem;
		min-height: 100px;
		resize: vertical;
		border: 1px solid $c-gray-xl;

		&:focus {
	    	border-color: #000;
	    }
	}

	.gform_body {
		overflow: hidden;
	}

	.gfield_label {
		font-weight: 700;
	}

	.hidden_label {
		.gfield_label {
			display: none;
		}
	}

	.gform_footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.gform_ajax_spinner {
			margin-left: 1rem;
		}
	}

	&\:address {
		clear: both;

		.gfield_label {
			display: none;
		}
		.ginput_complex {
			> span {
				display: flex;
				flex-direction: column-reverse;
				margin-bottom: 4rem;
			}
		}
	}

	.ginput_complex {
		label {
			font-size: 1.2rem;
			display: block;
			margin-top: 1rem;
			color: $c-gray-l;
			text-transform: uppercase;
			font-weight: 500;
			padding-left: 1rem;
		}
	}

	.validation_error {
		background: $c-brand-red;
		border-radius: .4rem;
		margin-bottom: 2rem;
		padding: 1rem;
		color: #fff;
	}

	.validation_message {
		color: $c-brand-red;
	}

	&--dark {
		input {
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: #fff;
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  color: #fff;
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  color: #fff;
			}
			&:-moz-placeholder { /* Firefox 18- */
			  color: #fff;
			}
		}
	}

	&--inline {
		.gform_wrapper {
			form {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				@include media('<tablet-sm') {
					flex-direction: column;
				}
			}
			.gform_fields {
				display: flex;
				flex-wrap: wrap;

				@include media('<phone-lg') {
					flex-direction: column;
				}

				.gfield {
					@include media('>=phone-lg') {
						width: 50%;
					}

					&.gfield--type-captcha {
						.gfield_label {
							display: none !important;
						}
						@include media('>=phone-lg') {
							width: 100%;
						}
					}
				}
			}
			.gform_body {
				width: 66.66%;

				@include media('<tablet-sm') {
					width: 100%;
				}
			}
			.gform_footer {
				width: 30%;
				align-items: flex-start;

				@include media('<tablet-sm') {
					width: 100%;
				}

				input.button,
				input[type=submit] {
					width: 100%;
				}
			}
			li input {
				width: 100%;
			}
			.validation_message {
				background: $c-brand-red;
				border-radius: .4rem;
				padding: .2rem;
				color: #fff;
			}
			.validation_error {
				order: 5;
				margin-top: 1.5rem;
				width: 100%;
			}
		}
	}
}
.m-shortcode {
	width: 100%;
}
.m-component.m-form .gform_confirmation_message {
	background-color: $c-brand-blue-m;
	padding: 0.5rem 1rem;
	color: #fff;
	font-weight: 600;
}

/**
 * Header
 */

body {
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center top;
}

$hamwidth: 2.4rem;
$hamheight: .2rem;
$hamcolor: $c-brand-blue;
$hamoffset: 3;
.m-burger {
	font-size: 0;
	display: block;
	width: 3rem;
	height: 1.7rem;
	transition: transform 500ms ease-in-out, color 250ms ease-in-out;

	@include media('>=mobile-nav') {
		display: none !important;
	}

	&--active:hover {
		&__lines {
			background: transparent;
		}

	}

	&--active &__lines {
		background: transparent;

		&::before, &::after {
			top: 0;
			left: 0;
			width: $hamwidth;
			transform-origin: 50% 50%;
			background: $hamcolor;
		}

		&::before {
			transform: rotate3d(0, 0, 1, 45deg);
		}

		&::after {
			transform: rotate3d(0, 0, 1, -45deg);
		}

	}

	&__lines {
		position: relative;
		top: 0;
		display: inline-block;
		width: $hamwidth;
		height: $hamheight;
		transition: all 250ms ease-in-out;
		background: $hamcolor;
		top: $hamheight * $hamoffset;

		&::before, &::after {
			position: absolute;
			right: 0;
			display: block;
			width: $hamwidth;
			height: $hamheight;
			content: '';
			transition: all 250ms ease-in-out;
			transform-origin: 0 center;
			background: $hamcolor;
		}

		&::before {
			top: $hamheight * $hamoffset;
		}

		&::after {
			top: -$hamheight * $hamoffset;
		}
	}
}
.m-responsive-navs {
	width: 100%;
	padding: 1rem 0 0;
	background: $c-brand-blue;

	li {
		text-align: center;

		&.mobile-only {
			@media screen and (min-width: 1024px) {
				display: none;
			}
		}

		&.mobile-sm-only {
			@include media('>=phone-md') {
				display: none;
			}
		}

		&.m-highlighted-link {
			a {
				background: $c-brand-orange;
			}
		}

		a {
			font-size: 1.6rem;
			display: inline-block;
    		padding: 1.3rem 1rem;
    		color: #fff;
    		position: relative;
    		width: 100%;
    		cursor: pointer;
		}

		&:not(:last-child) {
			&::after {
				display: block;
				content: '';
				width: 90%;
				margin-left: 5%;
				height: .1rem;
				background: lighten($c-brand-blue, 5%);
			}
		}

		&.menu-item-has-children {
			> a {
				position: relative;

				&::after {
					content: '';
					display: inline-block;
					height: 1rem;
    				width: 1rem;
					background: url(../images/icn_mobile_dropdown.svg) center/contain no-repeat;
					opacity: .5;
				    right: 8rem;
				    margin: 0 -.9rem 0 .5rem;
				}
			}

			&.active {
				> a {
					&::after {
						transform: rotate(-180deg);
					}
				}
			}
		}

		.sub-menu {
			display: none;
			background: darken($c-brand-blue, 5%);

			li {
				&:not(:last-child) {
					&::after {
						background: $c-brand-blue;
					}
				}

				a {
					font-size: 1.4rem;
				}
			}
		}
	}

	.menu-header-menu-container {
		.m-shop-link {
			> a {
				&:before {
    				font-size: .9rem;
					font-weight: bold;
					color: $c-brand-blue-m;
    				text-transform: uppercase;
    				display: block;
    				line-height: .7;
				}
			}	
		}
		.m-support-link {
			> a {
				&:after {
					display: inline-block;
					padding-left: .5rem;
				}
			}
		}
	}
}
.menu-main-menu-container {
	position: relative;

	.m-location-link {
		a {
			svg {
				content: '';
				display: inline-block;
				height: 3rem;
				width: 2rem;
				margin-right: .5rem;
				transform: translateY(-.5rem);
				margin-bottom: -2rem;

				path {
					fill: $c-brand-orange;
				}
			}
		}
		&:hover {
			a {
				svg {
					path {
						fill: #fff;
					}
				}
			}
		}
	}
}
// .m-megamenu {
// 	> .m-mainnav__submenu {
// 		position: absolute;
// 		width: 100%;
// 		max-width: 1100px;
// 		left: 50%;
// 		top: 100%;
// 		transform: translateX(-50%);
// 		border-top: .4rem solid $c-brand-blue;
// 		z-index: 9;
//     	background: #fff;
//     	padding: 3rem 3rem;
// 	}
// 	.m-mainnav__submenu {
// 		a {
// 			text-transform: none;
// 			font-size: 1.4rem;
// 			padding: 1rem 2rem;
// 			display: block;
// 			width: 100%;
// 			font-weight: bold;
// 			color: $c-gray-md;
// 		}

// 		li {
// 			width: 22rem;

// 			&:hover {
// 				.m-mainnav__submenu {
// 					height: auto;
// 					opacity: 1;
// 					transition: opacity .3s ease-in-out;
// 				}
// 			}
// 		}

// 		.m-mainnav__submenu {
// 			position: absolute;
// 			left: 25rem;
//     		top: 3rem;
//     		opacity: 0;
//     		height: 0;
//     		overflow: hidden;
//     		transition: opacity 0 ease-in-out;

//     		li {
//     			width: 26rem;
//     		}

// 			a {
// 				color: $c-brand-blue;
// 				font-weight: 400;
// 			}

// 			.m-mainnav__submenu {
// 				display: none;
// 			}
// 		}
// 	}
// }
.m-heading {
	width: 100%;

	sup {
		font-size: 40%;
	    position: relative;
	    top: -26px;
	}
	
	&--center {
		text-align: center;
	}

	&__secondary {
		display: block;
		width: 100%;
		font-size: 14rem;
		font-weight: 200;
		color: rgba(0,0,0,0.3);
		font-family: $font-b;
		margin-bottom: -6rem;
		line-height: 1;
	}
	&__primary {
		display: block;
		width: 100%;
		font-size: 14rem;
		font-weight: 600;
		font-family: $font-b;
		padding-left: 3rem;
		line-height: 1;
		margin-bottom: 3rem;

		&--green {
			color: $c-brand-blue-m;
		}
		&--yellow {
			color: $c-brand-orange;
		}
		&--orange {
			color: $c-brand-orange;
		}
		&--purple {
			color: $c-brand-blue;
		}
		&--blue {
			color: $c-brand-blue;
		}
	}

	> p {
		font-size: 1.7rem;

		&.-white {
			color: #fff;
		}
	}
}
hr-fancy {
	display: block;
	width: 100%;
}
.m-hr__line {
	display: block;
	height: 2px;
	background-color: $c-gray-xl;

	&--smline {
		width: 6rem;
	}
	&--mdline {
		width: 45rem;
	}
	&--lgline {
		width: 100%;
		background-color: transparent;
		background: linear-gradient(to right, transparentize($c-brand-blue-m, 1) 0%, $c-brand-blue-m 30%, $c-brand-blue-m 60%, transparentize($c-brand-blue-m, 1) 100%);
	}
}
#key-ingredients {
	.l-wrapper {
		@include media('<tablet-sm') {
			//padding-left: 0 !important;
			//padding-right: 0 !important;
		}
	}
}
.m-inglist {
	margin: 0 auto 3rem auto;

	@include media('<tablet-sm') {
		grid-row-gap: 2rem;
	}
}
.m-products {
	&--carousel {
		@include media('<laptop') {
			margin-left: -2rem;
			margin-right: -2rem;
			width: calc(100% + 4rem) !important;
			padding-bottom: 4rem;
		}

		.m-card {
			&__body {
				padding: 0 2rem;
			}
		}

		.glide__track {
			width: 100%;
		}

		.m-image {
			text-align: center;

			img {
				max-height: 28rem;
				width: auto;
			}
		}
	}

	&--grid {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-wrap: wrap;
		gap: 3rem;

		&--2 {
			> * {
				width: calc(50% - 8rem);
			}

			@media screen and (max-width: 500px) {
				width: calc(100% - 8rem);
			}
		}
		
		&--3 {
			> * {
				width: calc(33.33333% - 2rem);

				@media screen and (max-width: 768px) {
					width: calc(50% - 8rem);
				}

				@media screen and (max-width: 500px) {
					width: calc(100% - 8rem);
				}
			}
		}

		&--4 {
			> * {
				width: calc(25% - 2rem);
			}

			@media screen and (max-width: 960px) {
				width: calc(33.33333% - 2rem);
			}

			@media screen and (max-width: 768px) {
				width: calc(50% - 8rem);
			}

			@media screen and (max-width: 500px) {
				width: calc(100% - 8rem);
			}
		}
	}
}
.m-product {
	background-color: transparent !important;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	z-index: 0 !important;
	&__section {
		margin: 4rem 0;

		@include media('>=tablet-sm') {
			margin: 7rem 0;
		}

		@include media('>=tablet-lg') {
			margin: 10rem 0;
		}

		.l-col:not(.l-col--overflowchildren) {
			border-radius: 3px;
			padding: 4rem;

			@include media('<tablet-sm') {
				padding: 4rem 3rem !important;
			}

			.m-bgimage {
				top: 3rem;
				right: 3rem;
			}

			.m-heading,
			.m-contentblock {
				position: relative;
				z-index: 3;
			}
		}
		.l-col.l-col--overflowchildren {
			display: block;
			position: relative;
			z-index: 5;
		}
	} 
	&__reviews {
		position: relative;

		@include media('>=tablet-sm') {
			margin: 5rem 0 0;
			padding-bottom: 5rem;
		}

		@include media('>=tablet-lg') {
			margin: 10rem 0 0;
			padding-bottom: 10rem;
		}
	}
	&__posts {
		margin-top: 25rem;

		.l-wrapper {
			position: relative;
			top: -20rem;
			margin-bottom: -15rem;
		}

		&\:title {
			display: block;
			width: 100%;
			font-size: 6.6rem;
			font-weight: 600;
			color: #000;
			margin-bottom: 3.5rem;
			text-align: center;

			@include media('>=tablet-sm') {
				font-size: 6.6rem;
			}
		}
	}
}
.m-bgimage {
	display: block;
	width: 100%;
	min-height: 24rem;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: -2;
}
.m-contentblock {
	font-weight: 400;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	@include media('<=tablet-sm') {
		width: 100% !important;
	}

	&--narrow {
		width: 65%;
		margin-left: auto;
		margin-right: auto;

		@include media('<=tablet-lg') {
			width: 100%;
		}
	}

	p, ul, ol {
		font-size: 1.8rem;
		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}

	ul {
		list-style-type: disc;
		padding-left: 3rem;
	}

	li {
		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}

	ol {
		counter-reset: ol-list-counter;
		list-style: none;
		margin: 2rem 0;
		
		li {
			counter-increment: ol-list-counter;
			position: relative;
			color: $c-brand-blue;
			padding: 0rem 0rem 0rem 5rem;
		    //box-shadow: inset -1rem 0rem 2rem $c-brand-blue-xl;
		    border-radius: 2.7rem;

			&:not(:last-child) {
				margin-bottom: 1.5rem;

				&::after {
					display: block;
					content: '';
					width: 100%;
					height: .2rem;
					background: linear-gradient(90deg, rgba($c-brand-blue-xl, 0), $c-brand-blue-xl 15%, $c-brand-blue-xl 60%, rgba($c-brand-blue-xl, 0)) !important;
					margin: 2rem 0 0 -7rem;
				}
			}

			&::before {
				content: counter(ol-list-counter);
				color: #fff;
				font-size: 2rem;
				font-weight: bold;
				font-family: $font-h;
				position: absolute;
				left: 1rem;
				width: 3rem;
				height: 3rem;
				top: -.5rem;
				background: $c-brand-blue;
				border-radius: 50%;
				text-align: center;
				line-height: 1.5;
			}
		}
	}

	hr {
		border: 0;
    	border-top: 1px solid #E6E6E6;
    	margin: 2.5rem 0;
	}

	blockquote {
		margin-top: 7rem;
		p {
			position: relative;
		}
		p:first-of-type {
			&::before {
				content: "“";
				font-family: $font-s;
				font-size: 61rem;
				color: darken($c-brand-blue-xl, 5%);
				position: absolute;
				top: -32rem;
				left: -4rem;
				z-index: -1;
				
				@media screen and (max-width: 767px) {
					left: 1rem;
				}
			}
		}
		p:last-of-type {
			&::before {
				content: "”";
				font-family: $font-s;
				font-size: 61rem;
				color: darken($c-brand-blue-xl, 5%);
				position: absolute;
				top: -28rem;
				right: -4rem;
				z-index: -1;

				@media screen and (max-width: 767px) {
					right: 1rem;
				}
			}
		}

		cite {
			color: $c-brand-orange;
			font-family: $font-s;
			font-size: 6rem;
			display: flex;
			flex-wrap: wrap;
			justify-content: left;

			em {
				width: 100%;
				font-style: normal;
				
				b {
					color: $c-brand-blue;
					font-weight: 400;
				}
			}

			span {
				font-size: 1.7rem;
				color: $c-brand-orange;
				display: block;
			}

			small {
				display: block;
				padding-top: 2rem;
				border-top: 2px solid $c-brand-orange;
				color: $c-brand-blue;
				font-family: $font-b;
				font-weight: 400;
				font-size: 2rem;
				padding-right: 4rem;
				margin-left: 3rem;

				b {
					display: block;
					font-weight: 600;
				}
			}
		}
	}

	&:not(:last-child) {
		//margin-bottom: 2.5rem;
	}

	h6 {
		font-size: 2.6rem;
		font-weight: 600;
		color: $c-brand-blue-l;
		margin-bottom: 1rem;
		position: relative;

		&::before {
			content: '';
			width: 100%;
			display: block;
			height: 1px;
			background: linear-gradient(to right, 
										$c-gray-xl 0%, 
										$c-gray-xl calc(50% - 12rem),
										#fff calc(50% - 10rem),
										#fff calc(50% + 10rem),
										$c-gray-xl calc(50% + 12rem),
										$c-gray-xl 100%);
			position: absolute;
			top: 45%;
			z-index: -1;
		}
	}

	.m-form {
		&_wrapper {
			display: block;
			background-color: #fff;
			padding: 3rem 4rem;
			border-radius: 3rem;
			filter: drop-shadow(0 1rem 2rem rgba(0,0,0,0.1));

			.gform_submission_error {
				font-size: 2.2rem !important;
				color: $c-brand-red;
			}
		}

		.gfield_required {
			color: $c-gray-l;
			font-size: 95%;
			margin-left: 2px;
		}

		.gform_footer {
			margin-top: 2rem;
			justify-content: center;
		}

		.gfield_validation_message {
			margin-top: 1rem;
			color: $c-brand-red;
		}
	}
}
.large-content .m-contentblock {
	font-size: 2.2rem;
}
.l-overflow {
	@include media('<tablet-sm') {
		width: 100% !important;
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	@include media('>=tablet-sm') {
		//position: absolute;
		//top: 0;
		//transform: translateY(-50%);
	}
}
.m-image {
	display: block;

	picture {
		display: block;
		width: 100%;
		height: auto;
		img {
			//border-radius: 3px;
		}
	}

	&--circle {
		box-shadow: 1rem 2rem 2rem rgba(0,0,0,.1);
    	border-radius: 50%;

		@include media('<tablet-sm') {
    		max-width: 56rem;
    		margin: 0 auto 2rem;
    	}

		picture {
			img {
				float: left;
				border-radius: 50%;
			}
		}
	}
}
.m-video {
	display: block;
	width: 100%;
	cursor: pointer;
	margin-bottom: 1rem;

	&__thumb {
		position: relative;
		display: flex;
	}

	&__overlay {
		position: absolute;
		color: #fff;
		font-size: .8rem;
		font-weight: 700;
		line-height: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: rgba(#000, .3);

		svg {
			width: 7rem;
			margin-bottom: 1rem;

			path {
				fill: #fff;
			}
		}
	}

	.m-video__header {
		max-width: 40rem;
	    color: #fff;
	    text-align: center;
	    font-size: 1.6rem;
	    font-weight: 300;
	    margin-top: 2rem;
	    line-height: 1.3;

	    @include media('>=tablet-sm') {
			font-size: 2.6rem;
	    }

	    &--below {
	    	margin-top: 1rem;
	    	color: $c-brand-blue;
	    }
	}

	img {
		float: left;
	}
}
mini-product {
	display: block;
	transition: opacity 250ms ease-in-out;


}
.m-miniprod {
	&__header {
		.m-image {
			text-align: center;

			img {
				max-height: 20rem;
    			width: auto;
			}
		}
	}
	&__header,
	&__content {
		a {
			display: block;
		}
	}
	&__content {
		margin-top: 2rem;
		margin-bottom: .5rem;

		@include media('>=tablet-sm') {
			min-height: 9rem;
		}

		star-reviews {
			justify-content: center;
			margin-bottom: 1.5rem;
		}
		.m-stars {
			&__stars li {
				width: 1.8rem;
				height: 1.4rem;
			}
			&__reviews {
				font-size: 1.6rem;
				color: $c-brand-orange;
			}
		}

		p {
			text-align: center;
			font-size: 1.6rem;
		}
	}
	&__title {
		display: block;
		width: 100%;
		font-size: 2.7rem;
		color: $c-brand-blue;
		font-family: $font-b;
		font-weight: 400;
		margin-bottom: 1rem;
		text-align: center;
		padding: 0 1rem;
	}
	&__price {
		&--normal {
			display: block;
			width: 100%;
			text-align: center;
			font-size: 2rem;
			color: $c-brand-blue-l;
			font-weight: 600;
		}
	}
	&__footer {
		display: flex;
		align-items: center;
		justify-content: center;

		.m-button {
			@extend .m-button--orange;
		}
	}
}
.m-htimage {
	position: relative;
}
.m-hotspots {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 5;
}
.m-hs {
	display: flex;
	position: absolute;

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		position: absolute;
		top: -1.5rem;
		left: -1.5rem;
		width: 3rem;
		height: 3rem;
		font-size: 2.5rem;
		font-weight: 600;
		color: $c-brand-blue-m;
		background-color: #fff;
		border-radius: 100%;
		transition: all 250ms ease-in-out;
		line-height: 1.2;

		&:hover {
			transform: scale(1.1);
		}

		&.on {
			transform: rotate(-135deg);

			&:hover {
				transform: scale(1.1) rotate(-135deg);
			}
		}
	}

	&__content {
		display: block;
		position: absolute;
		top: 2.5rem;
		left: -2rem;
		background-color: #fff;
		border-radius: 3px;
		padding: 1.5rem 2rem;
		transition: opacity 250ms ease-in-out;
		pointer-events: all;
		opacity: 1;
		width: 20rem;
		
		* {
			line-height: 1.2;
		}

		&.hidden {
			opacity: 0;
			pointer-events: none;
		}
	}
}
.m-igfeed {
	display: block;
	width: 100%;

	@include media('>=phone-lg', '<tablet-sm') {
		margin-top: 5rem;
	}

	&__row {
		display: flex;
		align-items: stretch;
		justify-content: center;
		flex-wrap: wrap;
	}
}
ig-tile {
	display: block;
	width: 48%;

	@include media('<phone-lg') {
		width: calc(50% - 3rem);
    	margin: 1rem;
    }

	@include media('>=phone-lg') {
		width: 23%;

		&:first-of-type {
			transform: translateX(-70%) translateY(40%);
		}
		&:last-of-type {
			transform: translateX(70%) translateY(-60%);
		}
		&:nth-child(2) {
			transform: translateX(-60%) translateY(-40%);
		}
		&:nth-child(3) {
			transform: translateX(-50%) translateY(5%);
		}
		&:nth-child(4) {
			transform: translateX(-40%) translateY(-50%);
		}
		&:nth-child(5) {
			transform: translateX(40%) translateY(-30%);
		}
		&:nth-child(6) {
			transform: translateX(50%) translateY(15%);
		}
		&:nth-child(7) {
			transform: translateX(60%) translateY(-40%);
		}
	}


	a {
		display: block;
		width: 100%;
		padding-bottom: 100%;
		font-size: 0;
		position: relative;

		&::before {
			content: '';
			width: 100%;
			height: 100%;
			box-shadow: inset 0 0 15rem rgba(0,0,0,0.65);
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			transition: all 250ms ease-in-out;
		}

		svg {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			transition: opacity 250ms ease-in-out;
			opacity: 0;
		}
		&:hover {
			&::before,
			svg {
				opacity: 1;
			}
		}
	}
}

rating-summary {
	@include media('>=tablet-sm') {
		width: 33.33333333333333%;
		padding-right: 2rem;
		position: sticky;
		top: 13rem;
	}
}
review-timeline {
	@include media('<tablet-sm') {
		padding-top: 2rem;
		width: 100%;
	}
	@include media('>=tablet-sm') {
		width: 66.66666666666666%;
		padding-left: 2rem;
	}
}
.m-revsummary {
	padding: 2rem 3rem;
	background-color: $c-gray-xxl;
	border-radius: 3px;
	color: $c-gray-d;

	&__title {
		font-weight: 500;
		color: $c-brand-blue;
		margin-bottom: 2.5rem;
		margin-bottom: 2rem;

		@include media('>=tablet-sm') {
			font-size: 3.8rem;
		}
	}

	.m-stars {
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 2rem;
		color: $c-brand-blue;

		&__average {
			display: block;
			font-size: 3.4rem;
			font-weight: 600;
			margin-right: 1.5rem;
		}
		&__stars li {
			width: 2.6rem;
			height: 2.2rem;
		}
		&__reviews {
			width: 100%;
			margin-top: 0.5rem;
			font-size: 2rem;
			top: 0;
		}
	}
}
.m-ratingcounters {
	border-top: 1px solid $c-gray-xl;
	padding-top: 2rem;
}
.m-ratingcounter {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem 0;

	&__rating,
	&__total {
		font-size: 1.8rem;
		font-weight: 600;
	}
	&__rating {
		width: 7rem;
	}
	&__counter {
		display: block;
		width: calc(100% - 10rem);
		height: 2.2rem;
		border-radius: 3rem;
		background-color: $c-gray-xl;
	}
	&__bar {
		display: block;
		width: 0;
		transition: all 500ms ease-in-out;
		height: 100%;
		border-radius: 3rem;
		background-color: $c-brand-blue-m;
	}
	&__total {
		width: 3rem;
		text-align: right;
	}
}
rating-summary {
	@include media('<tablet-sm') {
		width: 100%;
	}
}
leave-review {
	display: block;
	background-color: $c-brand-blue;
	border-radius: 3px;
	padding: 2rem 4rem;
	overflow: hidden;
	margin-bottom: 4rem;
}
.m-leavereview {
	$root: &;
	&.active {
		#{$root}__body {
			max-height: 1200rem;
		}
	}
	&__header {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		position: relative;

		&::before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			bottom: -6rem;
			right: -6rem;
			background-image: url(../images/green-hex.svg);
			background-size: auto;
			background-position: right bottom;
			background-repeat: no-repeat;
		}

		.m-button {
			@extend .m-button--o-white;
			position: relative;
			z-index: 2;
		}
	}
	&__title {
		color: #fff;
		font-weight: 600;
		width: 100%;
		margin-bottom: 1rem;
		position: relative;
		z-index: 2;
	}
	&__subtitle {
		font-size: 1.8rem;
		font-weight: 400;
		font-family: $font-b;
		color: #fff;
		display: block;
		width: 100%;
		margin-bottom: 1.5rem;
		position: relative;
		z-index: 2;
	}
	&__body {
		margin-top: 2rem;
		max-height: 0;
		overflow: hidden;
		transition: max-height 500ms ease-in-out;

		background-color: #fff;
		width: 100%;
		margin-top: 3rem;

		.m-flashmsgs {
			width: calc(100% - 4rem);
			margin: 2rem;
		}

		form {
			padding: 2rem;
			position: relative;
			@include form-styles;

			&::before {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				background-color: rgba(255,255,255,0.85);
				opacity: 0;
				transition: opacity 250ms ease-in-out;
				position: absolute;
				z-index: 5;
				pointer-events: none;
			}
			&::after {
				content: '';
				@extend .spinner;
				position: absolute;
				z-index: 6;
				top: calc(50% - 1.5rem);
				left: calc(50% - 1.5rem);
				opacity: 0;
				transition: opacity 250ms ease-in-out;
				pointer-events: none;
			}

			&.loading {
				&::before,
				&::after {
					opacity: 1;
					pointer-events: all;
				}
			}
		}
		
		fieldset {
			margin-bottom: 2rem;

			span {
				display: block;
				width: 100%;
				margin-top: -3rem;
				font-size: 1.4rem;
				font-style: italic;
				color: $c-gray-md;
				margin-bottom: 1rem;
			}
		}
	}
}
review-item {
	display: block;
	width: 100%;
	padding: 3rem 0;
	border-bottom: 1px solid $c-gray-xl;
}
.m-product-reviews {
	.m-reviews__action {
		display: none;
	}
	review-item {
		padding: 3rem 0 1rem;
	}
}
.m-reviews {
	$root: &;
	&.loaded {
		#{$root}__end {
			display: block;
		}
		#{$root}__action {
			display: none;
		}
	}
	&.empty {
		#{$root}__empty {
			display: flex;
		}
		#{$root}__end {
			display: none;
		}
		#{$root}__action {
			display: none;
		}
	}
	&__action {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 3rem;

		.m-button {
			@extend .m-button--orange;
		}
	}
	&__end {
		display: none;
		width: 100%;
		text-align: center;
		color: $c-gray-l;
		margin-top: 2rem;
	}
	&__empty {
		display: none;
		align-items: center;
		justify-content: center;

		font-size: 1.6rem;
		color: $c-gray-md;
		font-weight: 600;
	}
}
.m-review {
	$root: &;
	&__meta {
		display: flex;
		width: 100%;
		align-items: center;
		margin-bottom: 2rem;

		@include media('<tablet-sm') {
			flex-wrap: wrap;
		}
	}
	&__author {
		text-transform: uppercase;
		font-weight: 600;
		font-size: 1.8rem;
		font-family: $font-b;
		margin: 0 5px 0 1rem;
		position: relative;
		top: 1px;

		@include media('<tablet-sm') {
			margin-left: 0;
		}
	}
	&__date {
		font-size: 1.8rem;
		position: relative;
		top: 1px;
	}
	&__body,
	&__footer {
		@include media('>=tablet-sm') {
			padding-left: 12.2rem;
		}
	}
	&__body {
		color: $c-gray-d;
		margin-bottom: 2rem;

		@include media('>=tablet-sm') {
			font-size: 2rem;
		}

		&--nostars {
			&,
			& + #{$root}__footer {
				padding-left: 1rem;
			}
		}
	}
	&__footer {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		&\:label {
			font-weight: 600;
			color: $c-gray-md;
			margin-right: 2rem;
			font-size: 1.6rem;
		}
	}
	&__counter {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-weight: 600;

		&--empty svg {
			opacity: 0.5;
		}

		svg {
			position: relative;
			top: -4px;
			margin-right: 0.75rem;
			* {
				fill: $c-brand-blue-m;
			}
		}
	}
	star-reviews {
		@include media('<tablet-sm') {
			width: 100%;
		}
	}
}
.m-postgrid {
	grid-column-gap: 4rem;
	grid-row-gap: 3rem;

	@include media('<tablet-lg') {
		grid-template-columns: 1fr;
	}
}
mini-post {
	display: block;
	background-color: #fff;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	//filter: drop-shadow(0 1rem 1rem rgba(0,0,0,0.1));
	position: relative;
	background: $c-gray-xxl;
	border-radius: .6rem;
	overflow: hidden;

	@include media('>=phone-lg', '<tablet-lg') {
		display: flex;

		&:nth-of-type(even) {
			flex-direction: row-reverse;
		}
	}
}
.m-minipost {

	&__header {
		display: block;
		position: relative;

		@include media('>=phone-lg', '<tablet-lg') {
			width: 50%;
			min-height: 32vw;
		}

		&__overlay {
			height: 100%;
			position: relative;
		    display: flex;
		    align-items: flex-end;
		    flex-direction: column;
		    align-content: flex-end;
		    justify-content: flex-end;
		    text-align: center;
		    z-index: 5;
		    background: linear-gradient(0deg, rgba($c-gray-d, .4), rgba($c-gray-d, 0)) !important;
		}

		a {
			display: block;
			height: 40vw;
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			overflow: hidden;

			@include media('>=tablet-sm') {
				height: 23vw;
			}

			@include media('>=tablet-lg') {
				height: 22rem;
			}

			defer-image {
				position: absolute;
			    top: 0;
			    height: 100%;
			    width: 100%;
			}

			defer-image,
			picture {
				display: block;
			}
			picture {
				width: 100%;
				height: 100%;
				object-fit: cover;

				img {
					object-fit: cover;
					height: 100%;
    				width: 100%;
				}
			}
		}
	}
	&__content {
		padding: 2.5rem 2rem 10rem;
		text-align: center;
		position: relative;

		@include media('>=phone-lg', '<tablet-lg') {
			width: 50%;
			text-align: left;
			padding: 2.5rem 3rem 3rem;
			justify-content: center;
		    display: flex;
		    flex-direction: column;
		}

		.m-button-container {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			bottom: 4.5rem;
			left: 0;
			width: 100%;

			@include media('>=phone-lg', '<tablet-lg') {
				justify-content: flex-start;
				position: relative;
				bottom: 0;
			}

			.m-button {
				@extend .m-button--orange;
			}
		}
	}
	&__title {
		font-size: 2.6rem;
		font-weight: 300;
		width: 100%;
		margin-bottom: 1rem;
		line-height: 1.2;
	}
	&__date {
		font-size: 1rem;
		letter-spacing: .2rem;
		width: 100%;
		color: $c-gray-md;
		margin-bottom: 2rem;
		font-weight: 600;
		display: block;
		text-transform: uppercase;
	}
	&__excerpt {
		color: $c-gray-md;
		font-size: 1.6rem;
		line-height: 1.5;
		margin-bottom: 1rem;
	}
}
plax-wrapper {
	display: block;
}
.m-plax {
	$root: &;
	display: block;
	width: 100%;
	position: absolute;
	z-index: 10;
	max-width: 45%;

	& + .l-wrapper {
		z-index: 20;
		position: relative;
	}

	@include media('<tablet-sm') {
		display: none;
	}

	&--top, {
		&,
		#{$root}__wrapper {
			top: 0;
		}
	}
	&--middle, {
		&,
		#{$root}__wrapper {
			top: 50%;
		}
	}
	&--left {
		&,
		#{$root}__wrapper {
			left: 0;
		}
	}
	&--bottom {
		&,
		#{$root}__wrapper {
			bottom: 0;
		}
	}
	&--right {
		&,
		#{$root}__wrapper {
			right: 0;
		}
	}

	&__wrapper {
		display: block;
		position: absolute;

		picture {
			display: block;

			img {
				max-height: 30rem;
				width: 100%;
			}
		}
	}
}
.m-fda {
	display: block;
	//background-color: $c-gray-l;
	//padding: 8rem 0 4rem;
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center top;

	p {
		display: block;
		background-color: #fff;
		padding: 3rem 1rem 1rem;
		font-weight: 600;
		font-size: 1.4rem;
		border-top: 1px solid $c-gray-xxl;
	}
}
.m-nsl {
	position: relative;
	padding: 5rem 0 3rem 0;
	background-size: cover;

	&::after {
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		background-color: rgba(0,0,0,0.3);
		top: 0;
		left: 0;
		z-index: 1;
		position: absolute;
	}

	.l-wrapper {
		z-index: 2;
		position: relative;
	}

	@include media('>=tablet-sm') {
	 	padding: 7rem 0 4rem 0
	 }

	@include media('>=tablet-lg') {
		padding: 11rem 0 6rem 0;
	}

	// &::before,
	// &::after {
	// 	content: '';
	// 	position: absolute;
	// 	left: 0;
	// 	right: 0;
	// }
	// // &::before {
	// // 	top: -.1rem;
	// // 	height: 3.5vw;
	// // 	background: url(../images/border_newsletter_top.svg) center/cover no-repeat;
	// // }
	// &::after {
	// 	bottom: -.1rem;
	// 	height: 7vw;
	// 	background: url(../images/border_newsletter_bottom.svg) center/cover no-repeat;
	// }

	.l-wrapper {
		width: 82rem;
	}

	.m-form_wrapper {
		width: 100%;

		@include media('>=tablet-lg') {
			width: calc(100% - 62rem);
		}
	}
}

.l-col {
	&__bgimage {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 0;
		bottom: 0;
		overflow: hidden;

		defer-image {
			//display: block;
    		//height: 100%;

    		picture {
    			//display: block;
    			//height: 100%;

    			img {
    				object-fit: cover;
    				max-width: none;
    				object-position: center;
				    height: 100%;
				    width: 100%;
    			}
    		}
		}

		&--full {
			//width: calc(100% + 50vw - #{$site-wrapped}/2 - 8px);
		}

		& ~ * {
			position: relative;
			z-index: 3;
		}
	}

	&:first-child .l-col__bgimage {
		//margin-left: calc(-50vw + #{$site-wrapped}/2 + 9px);
	}
}

.m-card {
	$root: &;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	position: relative;

	&--decorated {
		&::before {
			content: '';
			width: 100%;
			height: 1px;
			@include gradient-orange;
			display: block;
			position: absolute;
			z-index: 1;
			left: 0;
			top: 3rem;
			z-index: -1;
		}

		#{$root}__footer {
			position: relative;
			&::before {
				content: '';
				width: calc(50% - 10rem);
				height: 1px;
				@include gradient-orange;
				display: block;
				position: absolute;
				z-index: 1;
				left: 0;
				top: 50%;
				z-index: -1;
			}
			&::after {
				content: '';
				width: calc(50% - 10rem);
				height: 1px;
				@include gradient-orange;
				display: block;
				position: absolute;
				z-index: 1;
				right: 0;
				top: 50%;
				z-index: -1;
			}
		}
	}

	&__header {
		display: block;
		width: 100%;
		padding: 0 3rem;
		position: relative;

		defer-image,
		picture {
			display: block;
		}
		img {
			width: 100%;
			height: auto;
		}
	}
	&__body {
		display: block;
		width: 100%;
		margin: 3rem 0;
	}
	&__title,
	&__subtitle {
		display: block;
		width: 100%;
		text-align: center;
	}
	&__title {
		font-size: 2.4rem;
		color: $c-gray-md;
		margin-bottom: 1rem;

		a {
			color: $c-gray-md;
		}
	}
	&__subtitle {
		font-size: 1.8rem;
		font-family: $font-b;
		color: $c-brand-blue-l;
	}
	&__footer {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
	}
}


/**
 * Breadcrumbs
 */
.m-breadcrumbs {
	.current {
		font-weight: 600;
	}
	a {
		color: #000;

		&:hover {
			text-decoration: underline;
		}
	}

	+ .m-row {
		padding-top: 0rem;

		@include media('>=tablet-sm') {
			padding-top: 3rem;
		}
	}
}

/**
 * Testimonials
 */
.m-testimonial {
	text-align: center;
	padding: 3rem 5%;
	width: 100%;

	&__content {
		font-size: 2.1rem;
		padding: 0 0 3rem;
		max-width: 80rem;
    	margin: 0 auto;

		&:before {
			content:'“';
			font-size: 16.3rem;
			font-weight: 600;
			line-height: 1rem;
			color: #000;
			display: block;
			margin: 6rem auto 1rem;
			opacity: .06;
		}
	}
	&__thumb {
		margin: 0 auto;
		width: 9rem;
		border-radius: 5rem;

		img {
			border-radius: 5rem;
		}
	}
	&__title {
		font-size: 2rem;
		font-weight: 700;
		font-style: italic;
	}
}


/**
 * Sliders
 */
.glide {
	.glide__slide {
		//align-self: center;
	}
	.glide__arrow {
		padding: 2rem 0;
		background: none;
		border-radius: 4rem;
		border: .3rem solid $c-gray-l;
		height: 5rem;
		width: 5rem;
		text-align: center;
		position: absolute;
		bottom: -2rem;
		font-size: 0;
		outline: 0;
		cursor: pointer;
		transition: all .3s ease-in-out;

		@include media('>=laptop') {
			top: calc(50% - 4rem);
		}

		&:hover {
			background: $c-brand-orange;

			&:before {
				border-color: #fff;
			}
		}

		&:before {
			content: '';
			border: .5rem solid $c-brand-orange;
			transform: rotate(45deg);
			height: 1.6rem;
			width: 1.6rem;
			display: block;
			position: absolute;
		    top: calc(50% - .9rem);
		    left: calc(50% - 1.1rem);
		}

		&.glide__arrow--right {
			right: calc(50% - 6rem);

			@include media('>=laptop') {
				right: -6rem;
			}

			&:before {
				border-width: .4rem .4rem 0 0;
			}
		}

		&.glide__arrow--left {
			left: calc(50% - 6rem);

			@include media('>=laptop') {
				left: -6rem;
			}
			
			&:before {
				left: calc(50% - .5rem);
				border-width: 0 0 .4rem .4rem;
			}
		}
	}
}

.m-testimonial-slider {
	margin-bottom: 5rem;

	.m-testimonial {
		padding: 2rem 10rem;

		@media screen and (max-width: 600px) {
			padding: 2rem;
		}
	}
}

/**
 * Featured Product
 */
.m-feat-prod {
	&--image {
		z-index: 2;
	}

	&--img-left {
		.m-feat-prod__thumb {
			right: -15rem;
		}
		.m-feat-prod__cta {
			right: 0;

			.m-button {
		    	transform: translateX(50%);
		    }
		}
	}

	&--img-right {
		order: 2;

		.m-feat-prod__thumb {
			left: -15rem;
		}

		.m-feat-prod__cta {
			left: 0;

			.m-button {
		    	transform: translateX(-50%);
		    }
		}

		.m-feat-prod--content {
			padding-left: 7rem;
			padding-right: 19rem;
		}
	}

	&--content {
		transform: translateY(5rem);
		background-color: $c-gray-l;
		background: linear-gradient(0deg, #fff, $c-gray-l 50%) !important;
		align-items: center;
		padding: 5rem 7rem 5rem 19rem;
	}

	&__thumb {
		position: absolute;
	    width: 30rem;
	    top: 50%;
	    transform: translateY(-45%);
	}

	&__cta {
		position: absolute;
	    bottom: -2rem;
	}

	&__content {
		margin-bottom: 3rem;
	}

	&__review {
		padding: 3rem 4rem;
		background-color: $c-gray-l;

		&__content {
			margin-bottom: 1rem;
		}

		&__author {
			text-transform: uppercase;
			font-weight: 700;
			text-align: right;
		}
	}

	h6 {
		font-family: $font-b;
		letter-spacing: .2rem;
		color: $c-brand-blue-m;
	}

	h3 {
		font-size: 4.8rem;
		margin-bottom: 2rem;

		a:not(:hover) {
			color: #000;
		}
	}
}


/*
Video Embeds
*/
.m-embed-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    width: 100%;
    overflow: hidden;

    iframe,
	object,
	embed { 
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/*
Popups
*/
.m-popup {

	@include media('>=laptop') {
		display: flex;
	}

	* {
		pointer-events: none;
	}

	&.active {
		pointer-events: all;
		opacity: 1;
		display: flex;

		* {
			pointer-events: all;
		}
	}

	&__wrapper {
		position: relative;
		width: 100%;
		height: auto;
		max-width: 70rem;
		max-height: 96vh;
		overflow: scroll;
		background: #fff;

		&__inner {
			padding: 9rem 3rem;

			@include media('>=tablet-sm') {
				padding: 9rem 7rem;
			}
		}
	}

	&__close {
		position: absolute;
	    top: 0;
	    color: #fff;
	    font-size: 3.5rem;
	    display: block;
	    height: 7rem;
	    width: 7rem;
	    line-height: 1.9;
	    opacity: 1;
	    right: 0;
	    transition: all .3s ease-in-out;
	    text-align: center;
	    background: $c-brand-blue;
	    z-index: 999999;

	    &:hover {
	    	background: $c-brand-blue;
	    	color: #fff;
	    }
	}

	&__overlay {
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}

	&__content,
	&--content {
		width: 100%;
		transition: all .3s ease-in-out;

		&.hidden {
			opacity: 0;
		}

		ul {
			margin: 1.5rem 0 1rem 2rem;
			li {
				margin-bottom: .5rem;
				font-weight: 900;

				&::before {
					color: $c-brand-blue;
				}
			}
		}

		h3 {
			font-size: 3.5rem;
		}

		.m-popup__wrapper {
			max-width: 62rem;
		}
	}

	&--form {
		.m-popup__wrapper__inner {
			@include media('>=tablet-sm') {
				padding: 7rem 7rem 7rem;
			}
		}
		.m-form textarea {
			height: 18rem;
		}
	}

	&--video {
		.m-popup__wrapper {
			max-width: 80vw;
			max-height: 200vh;
			position: static;
			background: none;

			@include media('>=tablet-lg') {
				max-width: 70vw;
			}
		}
		.m-popup__wrapper__inner {
		    position: relative; 
			padding: 0 0 56.25%;
			overflow: hidden;
			max-width: 100%;
			height: 0;
			width: auto;

		    iframe, object, embed { 
		        position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
		    }
		}
	}

	&__nav {
		position: absolute;
	    font-size: 0;
	    transition: all .3s ease-in-out;
	    border: 0.1rem solid #fff;
	    height: 5rem;
	    width: 5rem;
	    top: calc(50% - 6rem);
	    transform: none;
	    z-index: 99;
	    padding: .7rem;
	    opacity: 1;
	    font-size: 0;
	    border-radius: 5rem;
	    outline: none;
	    cursor: pointer;
	    position: absolute;
	    transition: all .3s ease-in-out;

	    @include media('<tablet-sm') {
	    	display: none;
	    }

	    @include media('>=tablet-sm') {
			bottom: 5rem;
	    }

	    &:hover {
	    	background: $c-brand-blue;
	    	border-color: $c-brand-blue;
	    }
	}

	&__prev {
		left: 2rem;

		@include media('>=tablet-sm') {
			left: 5rem;
		}

		&::before {
		    content: '';
		    display: block;
		    border: 0.2rem solid #fff;
		    width: 1.4rem;
		    height: 1.4rem;
		    left: 1.2rem;
		    position: relative;
		    border-width: .2rem 0 0 .2rem;
		    transform: rotate(-45deg);
		    top: .8rem;
		}
	}

	&__next {
		right: 2rem;

		@include media('>=tablet-sm') {
			right: 5rem;
		}

		&::before {
		    content: '';
		    display: block;
		    border: 0.2rem solid #fff;
		    width: 1.4rem;
		    height: 1.4rem;
		    left: .8rem;
		    position: relative;
		    border-width: 0 .2rem .2rem 0;
		    transform: rotate(-45deg);
		    top: .8rem;
		}
	}
}


/**
 * Split Sections with Media / Content
 */
.m-split {
	&--content-r {
		.l-col:last-child {
			padding: 5rem 8rem 5rem 10rem;
		}
	}
	&--content-l {
		.l-col:first-child {
			padding: 5rem 10rem 5rem 8rem;
		}
	}
}


/**
 * Split Column Section
 */
.m-split-cols {
	justify-content: space-between;
	padding: 1rem 0;

	> .l-col {
		align-content: center;

		@include media('<tablet-sm') {
			&:not(:last-child()) {
				margin-bottom: 1rem;
			}
		}

		@include media('>=tablet-sm') {
			width: calc(50% - .5rem);
		}
	}
}

 

/**
 * Mini Cart
 */
mini-cart {
	display: block;
	width: 100vw;
	height: 100vh;
	background-color: transparent;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	transition: all 500ms ease-in-out;
	pointer-events: none;

	&.active {
		background-color: rgba(0,0,0,0.3);
		pointer-events: all;

		.m-minicart {
			right: 0;
		}
	}
}
.m-minicart {
	$root: &;
	display: block;
	width: 34rem;
	max-width: calc(100% - 6rem);
	height: 100vh;
	position: fixed;
	top: 0;
	right: -37rem;
	z-index: 9999;
	background-color: #fff;
	filter: drop-shadow(-1rem 0 1rem rgba(0,0,0,.2));
	background-repeat: no-repeat;
	background-size: 105% auto;
	background-position: center top;
	transition: all 500ms ease-in-out;
	opacity: 1 !important;

	&.loading {
		#{$root}__items {
			&::before,
			&::after {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	&__header {
		padding: 0 2rem;
	}

	&__title {
		font-family: $font-s;
		font-size: 5rem;
		font-weight: 400;
		color: $c-brand-orange;
		padding: 2rem 0 0;
		text-align: center;
		position: relative;
	}
	&__empty {
		display: block;
		width: 100%;
		margin: 2rem 0;
		padding: 1.5rem;
		color: $c-gray-l;
		border-top: 1px solid $c-gray-xxl;
		border-bottom: 1px solid $c-gray-xxl;
		line-height: 1;
		text-align: center;
	}
	&__items {
		display: block;
		width: 100%;
		height: calc(100% - 19.3rem);
		overflow: hidden;
		position: relative;
		padding: 0 2rem;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background-color: rgba(255,255,255,0.85);
			opacity: 0;
			transition: opacity 250ms ease-in-out;
			position: absolute;
			z-index: 5;
			pointer-events: none;
		}
		&::after {
			content: '';
			@extend .spinner;
			position: absolute;
			z-index: 6;
			top: calc(50% - 1.5rem);
			left: calc(50% - 1.5rem);
			opacity: 0;
			transition: opacity 250ms ease-in-out;
			pointer-events: none;
		}
	}
	&__item {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 2rem 0;
		border-bottom: 1px solid $c-gray-xxl;

		&\:image {
			width: 12rem;
			margin-right: 2rem;

			@include media('<phone-md') {
				width: 6rem;
				margin-right: 1.5rem;
			}

			&, a, defer-image, picture, img {
				display: block;
			}
		}
		&\:details {
			width: calc(100% - 14rem);
			display: flex;
			flex-wrap: wrap;
			line-height: 2;

			@include media('<phone-md') {
				width: calc(100% - 9rem);
			}

			a:not([href="#remove"]) {
				width: 100%;
				color: $c-gray-d;

				&:hover {
					color: $c-brand-blue-m;
				}
			}

			a[href="#remove"] {
				display: block;
				font-weight: bold;
				font-size: 1.4rem;
				text-transform: uppercase;
				margin-top: 2rem;
			}
		}
		&\:name {
			font-family: $font-b;
			font-size: 1.8rem;
			font-weight: 600;
		}
		&\:price,
		&\:quantity {
			color: $c-gray-md;
			font-size: 1.6rem;
			display: block;
			width: 100%;
			margin-top: 0.5rem;

			b {
				color: $c-brand-blue-m;
			}
		}
	}
	&__autoship {
		display: block;
		width: 100%;
		padding: 0.5rem 1rem;
		border-radius: 3rem;
		text-align: center;
		background-color: $c-brand-blue-m;
		text-transform: uppercase;
		margin-top: 1rem;
		font-size: 1.2rem;
		font-weight: 600;
		color: #fff;
	}
	&__footer {
		display: block;
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
	}
	&__totals {
		//background-color: lighten($c-gray-xl, 5%);
		padding: 1rem 2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $c-brand-blue;
	}
	&__actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1.5rem 2rem;
		background-color: $c-gray-xxl;

		.m-button--secondary {
			@extend .m-button--orange;
		}
		.m-button--primary {
			@extend .m-button--blue;
		}
	}
}
mini-cart-item {
	display: block;
}

.m-loginregister {
	margin-top: 0;
	margin-bottom: 0;
}
.m-login,
.m-register {
	display: block;
	background-color: #fff;
	border-radius: 3px;
	filter: drop-shadow(0 1rem 1rem rgba(0,0,0,0.1));
	width: 100%;
	padding: 4rem;

	&__title {
		font-size: 8rem;
		margin-bottom: 1rem;
		font-family: $font-s;
	}

	form {
		@include form-styles;
	}
}
#forgot-password-link {
	display: block;
	margin-top: 2rem;
}

/**
 * FOOTER
 */
body .m-footer {
	background-color: #fff;
	z-index: 999;

	.l-wrapper {
		padding: 6rem 0rem 4rem;
		width: 1240px;
	}

	.l-col {
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.menu {
			text-align: center;

			li {
				margin-bottom: 1rem;
			}

			a {
				font-size: 1.6rem;
				color: $c-brand-blue;
			}
		}
	}

	&__heading {
		color: $c-brand-orange;
		font-size: 1.9rem;
		letter-spacing: .2rem;
		margin: 2rem 0 1rem;

		@include media('>=tablet-sm') {
			margin: 3rem 0 2rem;
		}
	}

	&__col-md {
		@include media('<tablet-sm') {
			margin-bottom: 1rem;
		}

		@include media('<tablet-lg') {
			order: 2;
		}

		@include media('>=phone-lg') {
			width: 50%;
		}

		@include media('>=tablet-md') {
			width: 25%;
		}

		@include media('>=tablet-lg') {
			width: 17%;
		}
	}

	&__col-lg {
		width: 100%;

		@include media('<tablet-sm') {
			margin-bottom: 2rem;
		}

		@include media('<tablet-lg') {
			order: 1;
		}

		@include media('>=tablet-lg') {
			width: 26%;
		}
	}

	.m-brand {
		@include media('<tablet-lg') {
			width: 100%;
		}

		&__link {
			@include media('<tablet-lg') {
				display: block;
				text-align: center;
			}
		}

		&__mark {
			display: block;
			width: 15.2rem;
			height: 8rem;
			background-repeat: no-repeat;
			background-size: 100% auto;
			background-position: center;
			font-size: 0;

			@include media('<tablet-lg') {
				margin: 0 auto;
			}
		}
	}

	.menu-footer-menu-container {
		margin-top: 2.8rem;

		@include media('<tablet-lg') {
			width: 100%;
		}

		.menu {
			display: flex;
			align-items: center;
			justify-content: center;

			@include media('<tablet-sm') {
				flex-direction: column;
			}

			li {

				&:not(:last-child) {
					@include media('>=tablet-sm') {
						border-right: 1px solid $c-gray-d;
						padding-right: 1.5rem;
						margin-right: 1.5rem;
					}
				}
			}

			a {
				color: $c-gray-d;
				font-size: 1.6rem;
				font-weight: 600;

				@include media('<tablet-sm') {
					display: block;
					padding: 1rem;
				}
			}
		}
	}

	.m-social {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0;
		margin-top: 2.4rem;
		width: 100%;

		@include media('<tablet-lg') {
			width: 100%;
		}

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			transition: all .3s ease-in-out;
			background: none !important;

			div {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 1rem;
				width: 3rem;
				aspect-ratio: 1;
				background-color: #edab2f;
				border-radius: 5rem;
			}

			&:hover {
				transform: translateY(-.5rem);
			}

			svg {
				position: relative;
				z-index: 3;
				width: auto;
				height: 2rem;
				max-width: 2.2rem;

				path {
					fill: #fff;
				}
			}

			span {
				font-size: 1.6rem;
				color: #fff;
				text-decoration: none;
				font-weight: 600;
			}

			&:not(:last-child) {
				margin-right: 1.5rem;
			}
		}
	}

	&__bottom {
		padding: 5rem 0;

		.menu {
			padding: 0 0 3rem;
			display: flex;

			@include media('<tablet-sm') {
				flex-direction: column;
			}

			@include media('>=tablet-sm') {
				margin-left: 2rem;
			}
			
			li {
				line-height: .1;

				@include media('<tablet-sm') {
					padding: 1rem 0;
					text-align: center;
				}

				&:not(:last-child) {

					@include media('>=tablet-sm') {
						border-right: .1rem solid $c-gray-md;
						padding: 0 1rem 0 0;
						margin-right: 1rem;
					}
				}
			}
			a {
				font-size: 1.3rem;
				color: $c-gray-md;
				line-height: .9; 
			}
		}
	}
	.m-button--blue {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
}
.m-footertext {
	text-align: center;
	font-size: 1.2rem;
	color: $c-gray-md;
	padding: 3rem 0;

	p:not(:last-child) {
		margin-bottom: 0.75rem;
	}
}
.m-copy {
	text-align: center;
	font-size: 1.3rem;
	color: $c-gray-md;
	padding: 0 0 3rem;
	line-height: 1;

	@include media('<tablet-sm') {
		width: 100%;
	}
}
.m-map {
	background: url(../images/map.svg) center/contain no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5rem 0;


	.m-button {
		
	}

}
.m-faq-accordion {
	&__item {
		padding: 1rem 0;

		&:not(:last-of-type()) {
			border-bottom: .1rem solid $c-gray-m;
		}
	}

	&__title {
		padding: 1rem 2rem 1rem 4rem;
		position: relative;
		font-size: 2rem;
		font-weight: 700;
		cursor: pointer;
		line-height: 1.2;

		@include media('>=tablet-sm') {
			font-size: 2.4rem;
		}

		&:before {
			content: '+';
			position: absolute;
			border-radius: 5rem;
			background-color: $c-brand-blue-m;
			left: 0;
			top: 1.3rem;
			width: 2.6rem;
		    height: 2.6rem;
		    display: block;
		    color: #fff;
		    text-align: center;
		    line-height: 1.2;
		}

		&.open {
			&:before {
				content: '–';
				line-height: 1;
			}
		}
	}

	&__content {
		display: none;
		padding: 0 2rem 2rem 4rem;
	}
}

.m-compliance-info {
	.l-col--4 {
		@include media('>=tablet-sm') {
			width: 25%;
		}
		img {
			@include media('<tablet-lg') {
				width: 30rem;
			}
		}
	}

	.l-col--2\.5 {
		@include media('>=tablet-sm', '<tablet-lg') {
			width: 70%;
		}
	}

	&__details {
		.l-col--4 {
			@include media('<tablet-sm') {
				width: 50%;
				margin-bottom: 2rem !important;
			}
			@include media('>=tablet-sm', '<tablet-lg') {
				width: 25%;
				margin-bottom: 2rem !important;
			}
		}
	}
}


.m-border {
	position: absolute;
	z-index: 9;
	left: 0;
	width: 100%;
	overflow: hidden;

	&--top {
		top: -.7rem;
		transform: rotate(180deg);
	}

	&--bottom {
		bottom: -1rem;
	}

	svg {
		@include media('<tablet-sm') {
			width: 300%;
			margin-left: -100%;
		}
		path {
			fill: #fff;
		}
	}
}

.m-circle-callout {
	position: relative;

	&__thumb {
		//padding-bottom: calc(100% - 1rem);
		display: flex;
		border-radius: 50%;
		border: .5rem solid $c-brand-orange;
		background: #fff;

		@include media('>=phone-lg') {
			width: 17rem;
		}

		@include media('>=tablet-sm', '<tablet-md') {
			width: 11rem;
		}

		@include media('>=tablet-md', '<tablet-lg') {
			width: 12.5rem;
		}

		@include media('>=tablet-lg', '<laptop') {
			width: 14rem;
		}

		img {
			float: left;
			border-radius: 50%;
		}
	}
	&__product {
		position: absolute;
		bottom: -3rem;
		right: -26%;
		z-index: 5;
		width: 36%;
	}
	&__title {
		position: absolute !important;
		bottom: -1rem;
		color: #fff;
		padding: 1.5rem 1rem 1rem;
		width: 100%;
		text-align: center;
		font-size: 1.6rem;
		z-index: 3;
	}
}

.m-circle-splash-callouts {
	position: relative;

	@include media('>=phone-lg') {
		padding: 10%;
	}

	@include media('>=phone-lg', '<tablet-md') {
	    max-width: 60rem;
    	margin: 0 auto;
    }

    @include media('>=phone-lg', '<laptop') {
		margin-right: 10%;
    }

	&__bg {
		position: absolute;
		top: -14%;
	    right: -19%;
	    left: -16%;
	    z-index: 0;

	    @include media('<phone-lg') {
			display: none;
	    }
	}

	.m-circle {
		@include media('>=phone-lg') {
			padding-bottom: 100%;
			width: 100%;
			border-radius: 50%;
			border: .5rem solid $c-brand-orange;
			z-index: 1;
			position: relative;
		}
	}

	.m-circle-callout {

		@include media('<phone-lg') {
			display: inline-block;
    		float: left;
    		width: calc(50% - 11vw);
    		margin-right: 11vw;
    		margin-bottom: 5rem;
		}

		@include media('>=phone-lg') {
			position: absolute;
			z-index: 3;
			max-width: 16rem;
		}

		&:nth-of-type(odd) {
			@include media('>=phone-md', '<phone-lg') {
				clear: left;
			}
		}

		&:nth-of-type(even) {
			@include media('>=phone-md', '<phone-lg') {
				float: right;
				margin-top: -3vw;
			}
		}
		&:nth-of-type(1) {
			@include media('>=phone-lg') {
				top: -1%;
    			left: calc(50% - 7.5rem);
    		}
		}
		&:nth-of-type(2) {
			@include media('>=phone-lg') {
				top: 25%;
				right: calc(15% - 7.5rem);
			}
		}
		&:nth-of-type(3) {
			@include media('>=phone-lg') {
				top: 64%;
				right: calc(26% - 7.5rem);
			}
		}
		&:nth-of-type(4) {
			@include media('>=phone-lg') {
				top: 64%;
				left: calc(26% - 7.5rem);
			}
		}
		&:nth-of-type(5) {
			@include media('>=phone-lg') {
				top: 25%;
				left: calc(15% - 7.5rem);
			}
		}
	}
}


.m-awards.l-grid {
	gap: 6rem 7rem;
}
.m-award {
	position: relative;
	text-align: center;

	&__award {
		width: 50%;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		transition: all .3s ease-in-out;
		position: absolute;
	}

	&__title {
		color: $c-gray-md;
		text-align: center;
		transition: all .3s ease-in-out;
	}

	&__prod-thumb {
		transition: all .3s ease-in-out;
		max-height: 32rem;
	}

	&:hover {
		.m-award__title {
			color: $c-brand-orange;
		}
		.m-award__award {
			transform: translateY(-40%);
		}
		.m-award__prod-thumb {
			transform: translateY(-1rem);
		}
	}
}

.m-hyalock {
	text-align: center;
	padding: 1rem;
	border-radius: 3rem;
	background: #fff;
	max-width: 40rem;
	margin-bottom: 2rem;

	@include media('<tablet-sm') {
		margin: 0 auto 2rem;
	}

	@include media('>=tablet-sm') {
		padding: 5rem;
	}

	&__logo {
		width: 80%;
	    margin-top: -28%;
	    max-width: 22rem;
	    margin-bottom: 1.5rem;
	}

	&__description {
		color: $c-hyalock-blue;
		margin-bottom: 2rem;

		&::after {
			content: '';
			display: block;
			height: .2rem;
			width: 100%;
			background: linear-gradient(90deg, #fff, $c-gray-xxl, $c-gray-xxl, #fff) !important;
			margin-top: 2rem
		}
	}

	&__tagline {
		color: $c-brand-blue;
		font-size: 1.5rem;
    	font-weight: 700;

    	@include media('>=tablet-sm') {
			font-size: 2rem;
    	}

    	.u-orange {
    		color: $c-brand-orange;
    	}
	}
}

.m-hyalock-list {
	text-align: left;
    padding: 1rem 0 1rem 5rem;
    margin: 0 auto 2rem;
    max-width: 30rem;

	li {
		counter-increment: ol-list-counter;
		position: relative;
		font-weight: 400;
		color: $c-brand-blue;
		padding: 0;
	    box-shadow: none;
	    border-radius: 0;

		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}

		&::before {
			content: counter(ol-list-counter);
			color: #fff;
			font-size: 2rem;
			font-weight: bold;
			font-family: $font-h;
			position: absolute;
			left: -3.7rem;
			width: 3rem;
			height: 3rem;
			top: -.5rem;
			background: url(../images/hyalock-list.svg) center/contain no-repeat;
			text-align: center;
			line-height: 1.5;
		}
	}
}
.m-store-locator-section {
	.l-col {
		@include media('<laptop') {
			width: 100% !important;
		}
	}
}
.m-store-locator {
	border: .2rem solid $c-gray-xxl;
    border-radius: 1rem;

	#wpsl-wrap {
		margin: 0;

		#wpsl-result-list {
			li {
				padding: 1.2rem 2rem .5rem;
				display: flex;

				.wpsl-store-location {
					width: calc(100% - 9rem);
				}
			}
		}
	}
    .wpsl-search {
		border-radius: 1rem 1rem 0 0rem;
		background: $c-gray-xxl;
		margin: 0;
		padding: 1.5rem 2rem .5rem;
    }
	#wpsl-search-wrap .wpsl-input label,
	#wpsl-search-wrap div label {
		//font-weight: 700;
	}
	#wpsl-search-wrap input,
	#wpsl-search-wrap select,
	.wpsl-dropdown {
		height: auto;
	    padding: .9rem 1.4rem 1rem;
	    border-radius: 1rem;
	    border: 0;
	    display: block;
	}
	.wpsl-dropdown {
		padding: .1rem 0 .2rem 0;
	}
	#wpsl-search-wrap input#wpsl-search-btn {
	    border: 0;
		border-radius: 1.4rem;
		color: #fff;
		font-size: 1.8rem;
		font-weight: 700;
		width: auto;
		padding: .5rem 2rem .8rem;
		height: auto;
		background: $c-brand-orange;
		transition: all .3s ease-in-out;

		&:hover {
			background: lighten($c-brand-orange, 5%);
		}
	}
}
.m-shop {
	padding: 2rem 2rem 4rem;

	@include media('>=tablet-sm') {
		padding: 4rem 2rem;
	}

	.l-row > aside {
		display: block;
		top: 0;

		@include media('>=tablet-sm') {
			position: sticky;
			padding: 0 8rem 0 4rem;
		}
	}
	.m-empty {
		display: block;
		width: 100%;
		margin: 2rem 0;
		padding: 1.5rem;
		color: $c-gray-l;
		border-top: 1px solid $c-gray-xxl;
		border-bottom: 1px solid $c-gray-xxl;
		line-height: 1;
		text-align: center;
	}
}
.m-filter-toggle {
	@include media('>=tablet-sm') {
		display: none;
	}
}
.m-filter-container {
	@include media('<tablet-sm') {
		display: none;
	}

	@include media('>=tablet-sm') {
		display: block !important;
	}
}
filter-nav {
	display: block;
	margin-top: 2rem;

	&:not(:last-of-type) {
		margin-bottom: 3rem;

		@include media('>=tablet-sm') {
			margin-bottom: 4rem;
		}
	}
	h3 {
		font-size: 1.1rem;
		font-weight: 600;
		color: $c-gray-md;
		text-transform: uppercase;
		display: block;
		margin: 0;
		padding-bottom: 1rem;
		margin-bottom: 1rem;
		border-bottom: 1px solid $c-gray-xl;

		@include media('>=tablet-sm') {
			font-size: 1.3rem;
		}
	}
	ul {
		padding: 0 1rem;
	}
	li {
		display: block;
		padding: 0.3rem 0;
	}
	a {
		display: block;
		width: 100%;
		position: relative;
		color: $c-gray-md;
		transition: padding-left 175ms ease-in-out, margin-left 175ms ease-in-out;

		&:focus {
			color: $c-gray-md;
		}

		&:hover {
			&::before {
				background-color: $c-brand-blue;
				color: #fff;
			}
		}

		&.selected {
			font-weight: 600;
			color: $c-brand-blue;
			padding-left: 0.3rem;

			&::before {
				opacity: 1;
				transform: scale(1);
				left: -1.4rem;
			}
		}
		
		&::before {
			content: '✕';
			display: block;
			position: absolute;
			top: 4px;
			left: -1.2rem;
			padding: 1px 2px;
			border: 1px solid $c-brand-blue;
			border-radius: 1px;
			line-height: 1;
			font-size: 8px;
			font-weight: 600;
			opacity: 0;
			transform: scale(0.9);
			transition: opacity 250ms ease-in-out, background-color 250ms ease-in-out, color 250ms ease-in-out, transform 250ms ease-in-out, left 250ms ease-in-out;
		}
	}
}
.m-productactions {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: sticky;
	top: 0;
	background-color: #fff;
	padding: 0 0 2rem;
	width: 100%;
	z-index: 10;
	margin-bottom: 1rem;

	@include media('>=tablet-sm') {
		padding: 1rem 1.5rem;
	}
}
.m-productgrid {
	mini-product {
		opacity: 0;
		background-color: #fff;
		transition: opacity 250ms ease-in-out, filter 250ms ease-in-out, transform 250ms ease-in-out;

		@include media('>=tablet-sm') {
			padding: 2rem;
		}

		&:hover {
			@include media('>=tablet-sm') {
				transform: translateY(-3px);
				filter: drop-shadow(0 0.7rem 1rem rgba(0,0,0,0.1));
			}
		}

		&.show {
			opacity: 1;
		}
	}
	.m-miniprod {
		&__title {
			font-size: 2rem;
		}
		&__price--normal {
			font-size: 1.6rem;
		}
		&__content .m-stars__stars li {
			width: 1.9rem;
			height: 1.6rem;
		}
		&__content .m-stars__reviews {
			font-size: 1.4rem;
		}
	}
	mini-product  {
		.m-button {
			@extend .m-button--small;
		}
	}
}
.m-productsort,
.m-productsearch {
	display: flex;
	align-items: center;

	@include media('<tablet-sm') {
		width: 48%;
		flex-direction: column;
		align-items: flex-start;
	}

	label {
		display: block;
		font-size: 1.1rem;
		font-weight: 600;
		color: $c-gray-md;
		text-transform: uppercase;
		display: block;
		margin-right: 1rem;
		white-space: nowrap;

		@include media('<tablet-sm') {
			margin-bottom: .5rem;
		}

		@include media('>=tablet-sm') {
			font-size: 1.3rem;
		}
	}
	.m-select {
		border-radius: .5rem;
		padding: 5px;
		padding-right: 2.5rem;
		font-size: 1.5rem;
		border: 1px solid #d3d2cd;

		&__parent::after {
			border-top: 0.4rem solid #302d2b;
			border-left: 0.4rem solid transparent;
			border-right: 0.4rem solid transparent;
			border-bottom: 0rem solid transparent;
		}

		&:focus {
			border: 1px solid $c-gray-m;
		}
	}
	input {
		border-radius: .5rem;
		padding: 5px;
		font-size: 1.5rem;
		border: 1px solid #d3d2cd;

		@include media('<tablet-sm') {
			width: 100%;
		}

		&:focus {
			border: 1px solid $c-gray-m;
		}
	}
}

.m-comments {
	.comment-form-comment {
		display: flex;
		flex-direction: column;
	}
	input[type="submit"] {
		@extend .m-button;
		@extend .m-button--orange;
	}
}

.m-pagination {
	width: 100%;
	text-align: center;
	margin-top: 5rem;

	li {
		display: inline-block;
		margin: 0 .5rem;

		span,
		a {
			display: block;
			width: 4rem;
			height: 4rem;
			padding: .3rem 0;
			border-radius: 5rem;
			border: .2rem solid transparent;
			color: $c-brand-orange;
			font-weight: 700;
			text-align: center;
			line-height: 2;
			transition: all .3s ease-in-out;

			&.current {
				border-color: $c-brand-orange;
				background: $c-brand-orange;
				color: #fff;
			}

			&:hover {
				border-color: $c-brand-orange;
			}
		}
	}
}
.pum-container {
	transform: skewY(-5deg) translateZ(0);
	-webkit-font-smoothing: subpixel-antialiased;

	@include media('<phone-lg') {
		padding: 3rem !important;
	}

	&::before,
	&::after {
		content: '';
		background-image: url(https://hyalogic.com/wp-content/uploads/2019/08/hexagons.svg);
		background-size: 100% auto;
		background-position: center;
		background-repeat: none;
		width: 140px;
		height: 208px;
		display: block;
		position: absolute;
	}

	&::before {
		left: 0;
		bottom: 4rem;
		transform: translateX(-60%);
	}

	&::after {
		right: 0;
		top: 6rem;
		transform: translateX(60%);
	}

	.pum-title,
	.pum-content,
	.pum-close {
		transform: skewY(5deg) translateZ(0);
	}

	.pum-title {
		font-family: $font-s;
		font-size: 6rem;
	}

	.pum-content p {
		font-size: 1.7rem;
	}

	.gform_wrapper {
		.hidden_label>label {
			display: none !important;
		}

		form {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
		}

		.gform_body {
			width: calc(100% - 15rem);

			@include media('<phone-lg') {
				width: 100%;
			}
		}

		.gform_footer {
			width: 15rem;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			@include media('<phone-lg') {
				width: 100%;
				justify-content: center;
			}

			input {
				display: block;
				margin-right: 1rem;
				padding: 1.5rem 2rem;
				color: #fff;
				background-color: $c-brand-orange;
				border-radius: 1.6rem;
				font-size: 1.6rem;
				border: 0;
				font-weight: 600;
				text-align: center;
				cursor: pointer;
			}
		}

		.gform_fields {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-wrap: wrap;

			li {
				width: 48%;

				@include media('<phone-lg') {
					width: 100%;
					margin-bottom: 2rem;
				}
			}
		}

		.validation_error,
		.validation_message {
			color: $c-brand-red;
			padding: 1rem 2rem;
			background-color: #fff;
			margin: 1rem 0;
			font-weight: 600;
			border-radius: 5px;
		}

		input:not([type=submit]) {
			outline: 0;
			border: 0;
			background-color: transparent;
			color: #fff;
			font-size: 1.8rem;
			font-weight: 400;
			border-bottom: 2px solid #fff;
			padding: 1rem;
			width: 100%;
			border-radius: 0;

			&::placeholder {
				color: rgba(255, 255, 255, 0.8);
			}
		}
	}
}